/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';
// import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, Link } from 'gatsby';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib components
import { Container, Visible } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import ActualitiesMenu from '../page-components/page-Actualities-components/index';
import ActualitiesBloc from '../shared/constants/actualities';

/* -------------------------------------------------------------------------- */
/*                       Actualities Details  Page                            */
/* -------------------------------------------------------------------------- */

function ActualitiesDetailsPage({ location }) {
  /* ********************************** HOOKS ********************************* */
  const [searchInput, setSearchInput] = useState('');

  // Localization
  // const { t } = useTranslation();

  /* ********************************** CONST ********************************* */
  const getSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Les Nouvelles" />
      <Container>
        <Visible lg xl xxl>
          <Container className="top flex items-center fluid">
            <Link className="breadcrumb__link" to="/">
              Accueil
            </Link>
            <span className="m-r-l">/</span>
            <Link
              className="breadcrumb__link"
              to={`/${location.pathname.split('/')[1]}`}
            >
              {location.pathname.split('/')[1]}
            </Link>
          </Container>
        </Visible>
      </Container>
      <ActualitiesMenu getSearchInput={getSearchInput} />
      <ActualitiesBloc searchInput={searchInput} />
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

ActualitiesDetailsPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default ActualitiesDetailsPage;
