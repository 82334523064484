/* eslint-disable comma-dangle */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

// Axios
import axios from 'axios';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container, Col } from 'react-grid-system';

// UI local component
import ActualityItem from '../../page-components/page-index/single-actuality';

// APIs
import {
  LINK_API,
  API_JALIOS_ACTUALITIES,
  JALIOS_MEDIA_UPLOADS,
} from '../APIs/index';

// Style
import '../../page-components/Actualities/index.scss';
// assets
import IMAGE_TEST from '../../images/Actualities-page/test-image.jpg';
/* -------------------------------------------------------------------------- */
/*                            Actualities Bloc                                */
/* -------------------------------------------------------------------------- */

function ActualitiesBloc({ searchInput, actualitiesData, loader }) {
  /* -------------------------------- HOOKS --------------------------------- */
  const [jaliosDataActualities, setJaliosDataActualities] = useState();

  /* ------------------------------ CONSTS ---------------------------------- */

  /* ****************************** CALLBACKS ******************************* */

  /* ------------------------------- HELPERS -------------------------------- */
  /* ================ GET JALIOS ACTUALITIES ==================== */
  async function fetchJaliousActualities() {
    try {
      const { data } = await axios(API_JALIOS_ACTUALITIES, {
        headers: {
          Accept: 'application/json',
        },
      });
      setJaliosDataActualities(data.dataSet);
    } catch (error) {
      setJaliosDataActualities([]);
    }
  }

  useEffect(() => {
    fetchJaliousActualities();
  }, []);

  const actualitiesDataPreSort = actualitiesData?.map(
    ({ attributes: { date } }, index) => {
      const timestamp = new Date(date).getTime();
      return { type: 'strapi', timestamp, index };
    },
  );

  const jaliosDataActualitiesPreSort = jaliosDataActualities?.map(
    ({ udate }, index) => {
      const timestamp = new Date(udate).getTime();
      return { type: 'jalios', timestamp, index };
    },
  );

  const sortedActualities = [];

  if (
    actualitiesDataPreSort !== undefined &&
    jaliosDataActualitiesPreSort !== undefined
  ) {
    const actualitiesArray = [
      ...actualitiesDataPreSort,
      ...jaliosDataActualitiesPreSort,
    ].sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
    sortedActualities.push(...actualitiesArray);
  }

  function renderAllActualities() {
    return sortedActualities?.map(({ type, index }) => {
      if (type === 'strapi') {
        const actuality = actualitiesData[index];
        return (
          <Col
            className="actualite fluid"
            lg={4}
            md={4}
            sm={12}
            xs={12}
            key={actuality.id}
            fluid
            onClick={() => {
              navigate('/details-actualite', {
                state: { Source: 'Strapi', actuality },
              });
            }}
          >
            <ActualityItem
              image={
                actuality?.attributes?.picture?.data?.length > 0
                  ? LINK_API +
                    actuality?.attributes?.picture?.data[0]?.attributes?.url
                  : null
              }
              title={actuality?.attributes?.title}
            />
          </Col>
        );
      }
      if (type === 'jalios') {
        const actuality = jaliosDataActualities[index];
        const { title, picture } = actuality;
        const test = JALIOS_MEDIA_UPLOADS(picture).length;
        const rendredImage = test > 31 ? JALIOS_MEDIA_UPLOADS(picture) : null;
        return (
          <Col
            className="actualite fluid"
            lg={4}
            md={4}
            sm={12}
            xs={12}
            key={actuality.id}
            onClick={() => {
              navigate('/details-actualite', {
                state: { Source: 'Jalios', actuality },
              });
            }}
          >
            <ActualityItem image={rendredImage || IMAGE_TEST} title={title} />
          </Col>
        );
      }
    });
  }

  /* ================ FETCH EVENTS DATA ==================== */

  /* ******************************** RENDERING ******************************* */
  return (
    <Container id="actualities" className="actualities-section fluid" fluid>
      <div
        className="actualities-wrapper flex flex-wrap justify-between fluid"
        fluid
      >
        {loader ? (
          <Container className="loader fluid" />
        ) : (
          <>{renderAllActualities()}</>
        )}
      </div>
    </Container>
  );
}

ActualitiesBloc.propTypes = {
  loader: PropTypes.bool.isRequired,
  searchInput: PropTypes.string.isRequired,
  actualitiesData: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default ActualitiesBloc;
