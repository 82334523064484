/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container } from 'react-grid-system';

// UI local components
import PageTitle from '../page-title/index';
import FormMenu from '../../helpers/FormMenu';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                           Menu Bar Langin Page                             */
/* -------------------------------------------------------------------------- */

function ActualitiesMenu({ getSearchInput }) {
  /* ****************************** RENDERING ******************************* */
  return (
    <Container className="actuality-menu fluid" fluid>
      <PageTitle subtitle="Santé" title="Actualités" />
      <FormMenu getSearchInput={getSearchInput} />
    </Container>
  );
}

ActualitiesMenu.propTypes = {
  getSearchInput: PropTypes.string.isRequired,
};

export default ActualitiesMenu;
